<template>
  <div>
    <div>
      <h2
        v-if="title"
        class="ml-n2"
      >
        {{ title }}
      </h2>
    </div>
    <div class="pb-4 ml-n2 mb-2">
      <h4
        v-if="subtitle"
        class="pr-4"
      >
        {{ subtitle }}
      </h4>
    </div>

    <v-row v-if="!disableCompany">
      <v-text-field
        ref="name"
        v-model.lazy="contact.companyName"
        outlined
        label="Firma"
        validate-on-blur
        :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
      />
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        class="pa-0 pr-0 pr-md-2"
      >
        <v-text-field
          ref="contact.firstname"
          v-model.lazy="contact.firstname"
          outlined
          label="Vorname"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="contact.lastname"
          v-model.lazy="contact.lastname"
          outlined
          label="Nachname"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pa-0 pr-0 pr-md-2"
      >
        <PhoneField
          v-model="contact.phone"
          :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="email"
          v-model.lazy="contact.email"
          type="email"
          outlined
          label="E-Mail"
          :rules="[rules.email, rules.optionalForOnboarding(hasOptionalFields)]"
          validate-on-blur
        />
      </v-col>
    </v-row>

    <v-row>
      <v-text-field
        ref="street"
        v-model.lazy="contact.address.street"
        outlined
        label="Straße"
        validate-on-blur
        :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
      />
    </v-row>

    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="3"
        class="pa-0 pr-0 pr-md-2"
      >
        <v-text-field
          ref="zip"
          v-model.lazy="contact.address.zip"
          outlined
          type="number"
          label="PLZ"
          :rules="[rules.zip, rules.length({min: 4, max: 5}), rules.optionalForOnboarding(hasOptionalFields)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0 pr-0 pr-md-2"
      >
        <v-text-field
          ref="city"
          v-model.lazy="contact.address.city"
          outlined
          label="Stadt"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pa-0"
      >
        <v-select
          ref="country"
          v-model.lazy="contact.address.country"
          outlined
          label="Land"
          validate-on-blur
          :items="availableCountryItems"
          :rules="[rules.optionalForOnboarding(hasOptionalFields)]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { email, zip, length, optionalForOnboarding } from '@/lib/validation'
import PhoneField from '@/components/PhoneField.vue'
import countries from '@/mixins/countries'

export default {
  components: {
    PhoneField
  },
  mixins: [countries],
  props: {
    contact: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    hasOptionalFields: {
      type: Boolean,
      default: false
    },
    disableCompany: Boolean
  },
  created () {
    this.rules = { email, zip, length, optionalForOnboarding }
  }
}
</script>
